import { useEffect } from 'react';
import '../Assets/bootstrap.min.css';
import '../Assets/styles.css'
import PlayerList from './playerList';




function Header() {
    useEffect(() => {
    const radioPlayerDiv = document.querySelector('#radioplayer');
    const playbutton = document.querySelector('#playbutton');
    const playerList = document.querySelector('#playchannel');
    
    const playChannel = (channelURL) => {
       radioPlayerDiv.innerHTML = `<audio id="srStream" autoplay controls>
         <source src="${channelURL}" type="audio/mpeg"/></audio>`;
    };
    
    playbutton.addEventListener('click', function () {
       if (playbutton.innerHTML === 'Spela') {
          playChannel(playerList.value);
          playbutton.innerHTML = 'Pausa';
       } else if (playbutton.innerHTML === 'Pausa') {
          const streamer = document.querySelector('#srStream');
          streamer.pause(); //Pausa
          playbutton.innerHTML = 'Spela';
       }
    });
     }, []);

   return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
         <div className="container">
            <div className="d-flex flex-grow-1">
               <span className="w-100 d-lg-none d-block"></span>
               <a className="navbar-brand" href="#">
                  SR Öppet API
               </a>
               <div className="w-100 text-right">
                  <button
                     className="navbar-toggler"
                     type="button"
                     data-bs-toggle="collapse"
                     data-bs-target="#myNavbar7">
                     <span className="navbar-toggler-icon"></span>
                  </button>
               </div>
            </div>
            <div
               className="collapse navbar-collapse flex-grow-1 text-right"
               id="myNavbar7">
               <ul className="navbar-nav ms-auto flex-nowrap">
                  <li className="mx-2">
                     <select
                        id="playchannel"
                        className="form-select"
                        aria-label="Default select example">
                        <PlayerList />
                     </select>
                  </li>
                  <li className="nav-item">
                     <button className="btn btn-primary" id="playbutton">
                        Spela
                     </button>
                  </li>
               </ul>
            </div>
         </div>
      </nav>
   );
}

export default Header;
