import '../Assets/bootstrap.min.css';

function Footer() {
  return (
    <footer>
    <p>En footer!</p>
    <div className="radioplayer" id="radioplayer"></div>
</footer>
  );
}

export default Footer;

