import { useState, useEffect } from 'react';
import '../Assets/bootstrap.min.css';
import { useChannelContext } from '../Contexts/currentChannelContext';

export const PlayerList = () => {
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const fetchChannels = async () => {
       const data = await fetch(
          `https://api.sr.se/api/v2/channels?format=json&pagination=false&indent=true`
       );
       const SRobject = await data.json();
       const channels = SRobject.channels;
       setChannels(channels);
    };
    fetchChannels();
 }, []);


   return (
    <>
    {channels.map((channel, idx) => <option key={idx} value={channel.liveaudio.url}>{channel.name}</option>)}
    </>
   );
};

export default PlayerList;